<template>
    <div class="prtf-page" v-loading="loading">
        <div class="prtf-page-header">
            <div class="search-isin-header-col_1">Predefined Filters</div>
            <div class="search-isin-header-col_2">
                <el-select
                    v-model="selected_filter_id"
                    placeholder="Select filter"
                    size="small"
                    clearable
                    popperClass="si-filter-selection"
                    @change="set_selected_filter">
                    <el-option
                        v-for="item in filters_list"
                        :key="item._id"
                        :label="item.name"
                        :value="item._id">
                        <div class="si-filter-selection-name">{{ item.name }}</div>
                        <div class="si-filter-selection-descr" v-if="item.description">{{ item.description }}</div>
                    </el-option>
                </el-select>
            </div>
            <div class="search-isin-header-col_3">{{filters_list.length}} filters</div>
        </div>

        <div class="search-isin-body">
            <div class="search-isin-body-result-buttons">
                <div class="search-isin-selectedfilter">
                    <EditUserFilter 
                        v-if="selected_filter_id && selected_filter" 
                        v-model="selected_filter"
                        @change="filter_changed_event" />
                </div>
                <div class="search-isin-results-btns">
                    <div class="search-isin-results-btn">
                        <el-button type="primary" :disabled="!result_qty" @click="get_report('grid')">
                            Show results grid (<FormattedNumber :value="result_qty" zeros/>)
                        </el-button>
                    </div>
                    <div class="search-isin-results-btn">
                        <el-button type="primary" :disabled="!result_qty" @click="get_report('chart')">
                            Chart mode (<FormattedNumber :value="result_qty" zeros/>)
                        </el-button>
                    </div>
                </div>
            </div>

            <div class="search-isin-body-datepicker">
                <div class="si-filter-block-title">Filter</div>
                <div class="si-filter-asset">
                    <el-radio-group v-model="asset" :disabled="asset_select_disabled" @change="set_asset">
                        <el-radio label="bond">Bonds</el-radio>
                        <el-radio label="equity">Equities</el-radio>
                    </el-radio-group>
                </div>
                <div class="si-filter-datepicker">
                    <el-date-picker
                        v-model="selected_date"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        @change="set_date" />
                </div>
            </div>

            <Filters 
                ref="FiltersBlock" 
                :fields="fields" 
                :selected_date="selected_date" 
                :selected_asset="asset" 
                @set-filters="set_filters"
                @set-loading="set_loading" />

            
            <Columns 
                ref="ColumnsBlock" 
                :fields="fields"
                @set-fields="set_columns" />

            <div class="search-isin-body-filter-buttons">
                <div class="spacer"></div>
                <el-button size="medium" 
                    type="primary" 
                    plain 
                    :disabled="filter_has_errors" 
                    @click="save_new_filter_open = true">
                    Save as new filter
                </el-button>
                <el-button 
                    size="medium" 
                    type="primary" 
                    plain 
                    v-if="selected_filter_id" 
                    :disabled="filter_has_errors" 
                    @click="filter_save_event">
                    Save filter changes
                </el-button>
                <el-button 
                    size="medium" 
                    type="danger" 
                    v-if="selected_filter_id" 
                    @click="filter_delete_event">
                    Delete filter
                </el-button>
            </div>
        </div>

        <NewUserFilter 
            :open="save_new_filter_open" 
            @closed="save_new_filter_open = false"
            @saved="new_filter_saved_event"
            :filters="filters"
            :columns="columns"
            :asset="asset"
            v-if="filters && !filter_has_errors" />

    </div>
</template>

<script>
import { mapState } from 'vuex'
import FormattedNumber from '@/components/Formats/FormattedNumber'

import Filters from './Filters'
import Columns from './Columns'
import NewUserFilter from './NewUserFilter'
import EditUserFilter from './EditUserFilter'

export default {
    components: {
        FormattedNumber,
        Filters,
        Columns,
        NewUserFilter,
        EditUserFilter,
    },

    data(){
        return {
            selected_filter_id   : null,
            selected_filter      : undefined,
            fields               : [],
            asset                : 'bond', 
            selected_date        : new Date(),
            filters              : undefined,
            columns              : undefined,
            filter_has_errors    : true,
            result_qty           : 0,
            save_new_filter_open : false,
            loading              :false,
        }
    },

    computed: {
        ...mapState({
            user                    : state => state.app.user,
            filters_list            : state => state.userFilters.list,
            filters_list_loading    : state => state.userFilters.dataLoading,
            current_filter          : state => state.userFilters.current,
        }),

        asset_select_disabled(){
            if (this.selected_filter_id)
                return true;
            else if (this.filters && (this.filters.length > 1 || this.filters[0].field))
                return true;
        }
    },

    methods: {
        load_init_data(data){
            if (data) {
                this.asset = data.asset;
                this.selected_date = data.date;
                this.selected_filter_id = data.filter_id;
                this.columns = data.columns;
                this.filters = data.filters;
            }
            this.$store.dispatch('userFilters/getList', {user_id: this.user.id}).then(() => {
                if (this.selected_filter_id) {
                    this.selected_filter = this.filters_list.find(x => x._id === this.selected_filter_id);
                }
                this.get_fields().then(() => {
                    this.$refs.FiltersBlock.load_filters(this.filters);
                    this.$refs.ColumnsBlock.load_columns(this.columns);
                })
            
                
            })
        },

        get_fields(){
            // this.fields = this.all_fields.filter(f => { return f.owner.includes(this.asset) })
            return this.$store.dispatch('searchIsin/searchIsinFields', this.asset).then((response) => {
                this.fields = response
            })
        },

        get_path_data(){
            let filters = undefined
            if (this.filters) {
                filters = JSON.parse(JSON.stringify(this.filters))
                filters.forEach(f => {
                    delete f.has_errors
                    delete f.count_result
                })
            }
            
            return {
                filters   : filters,
                columns   : this.columns,
                date      : this.selected_date,
                asset     : this.asset,
                filter_id : this.selected_filter_id,
            };
        },

        set_selected_filter(){
            if (this.selected_filter_id) {
                this.selected_filter = this.filters_list.find(x => x._id === this.selected_filter_id);
                if (this.selected_filter) {
                    this.filters = this.selected_filter.filters
                    this.columns = this.selected_filter.fields
                    this.asset = this.selected_filter.asset
                }
                else {
                    this.selected_filter_id = null;
                    this.selected_filter = null;
                    this.filters = undefined
                    this.columns = undefined
                }
            }
            else {
                this.selected_filter = null;
                this.filters = undefined
                this.columns = undefined
            }

            this.get_fields().then(() => {
                this.$refs.FiltersBlock.load_filters(this.filters);
                this.$refs.ColumnsBlock.load_columns(this.columns);

                this.$emit('change-path', this.get_path_data());
            })
        },

        set_asset(){
            this.filters = undefined
            this.columns = undefined
            this.get_fields();
            this.$emit('change-path', this.get_path_data());
        },

        set_date(){
            this.$emit('change-path', this.get_path_data());
        },

        set_filters(filters, filter_has_errors, result_qty){
            this.filters = filters
            this.filter_has_errors = filter_has_errors
            this.result_qty = result_qty
            this.$emit('change-path', this.get_path_data());
        },

        set_columns(columns){
            this.columns = columns
            this.$emit('change-path', this.get_path_data());
        },

        set_loading(value){
            this.loading = value
        },

        new_filter_saved_event(){
            this.$store.dispatch('userFilters/getList', {type: this.filter_type, user_id: this.user.id}).then(() => {
                this.selected_filter_id = this.current_filter._id;
                this.set_selected_filter()
            })
        },

        filter_changed_event(){
            this.selected_filter = this.filters_list.find(x => x._id === this.selected_filter_id);
        },

        filter_delete_event(){
            this.$confirm('Filter will be deleted.', 'DELETE FILTER', {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                type: 'warning',
                confirmButtonClass: 'red-confirm-btn'
            }).then(() => {
                this.$store.dispatch('userFilters/deleteItem', {id: this.selected_filter._id, etag: this.selected_filter._etag}).then(() => {
                    this.selected_filter_id = null;
                    this.set_selected_filter();
                });
            }).catch(() => {});
        },

        filter_save_event(){
            let filter_data = JSON.parse(JSON.stringify(this.selected_filter))
            filter_data.filters.forEach(f => {
                delete f.count_result;
                delete f.has_errors;
            })
            this.$store.dispatch('userFilters/updateItem', filter_data).then(() => {
                this.$set(this.selected_filter, '_etag', this.current_filter._etag)
            })
        },

        get_report(view_type) {
            let data = this.get_path_data();
            data.view_type = view_type;
            data.fields = this.fields
            this.$emit("get-result", data)
        }
    }
}
</script>
