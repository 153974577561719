<template>
    <div class="search-isin-body-columns">
        <div class="si-body-columns-title">Columns</div>
        <div class="si-body-columns-wrapper">

            <el-tag
                v-for="column in columns"
                :key="column"
                closable
                @close="remove_column_from_list(column)">
                {{get_column_name(column)}}
            </el-tag>

            <div class="si-body-columns-add_btn">
                <el-button type="text" @click="add_column">
                    <i class="el-icon-circle-plus-outline"></i>
                </el-button>
            </div>
        </div>

        <el-dialog
        :visible.sync="show_modal"
        width="90%"
        append-to-body
        modal-append-to-body
        :close-on-click-modal="false">
            <template slot="title">
                <div>
                    <div class="tl-dialog-title">
                        <span>Add columns</span>
                    </div>
                </div>
            </template>
            <div class="si-body-popup_columns-wrapper">
                <div 
                    v-for="column in fields" 
                    :key="column._id" 
                    class="si-body-popup_columns-item"
                    @click="add_column_to_list(column)"
                    v-bind:class="{ active: is_active_column(column) }">
                    {{column.app_name}}
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        fields: {
            type: Array,
            required: true
        },
    },

    data(){
        return {
             columns: [],
             show_modal: false,
        }
    },

    methods: {
        load_columns(columns){
            if (columns)
                this.columns = columns;
            else
                this.columns = [];
        },

        add_column(){
            this.show_modal = true;
        },

        add_column_to_list(column){
            if (this.is_active_column(column)) {
                this.remove_column_from_list(column)
            }
            else {
                this.columns.push(column.orkl_name)
            }
            this.$emit('set-fields', this.columns)
        },
        remove_column_from_list(column) {
            let index = this.columns.findIndex(x => x === column)
            this.columns.splice(index, 1);
        },

        is_active_column(column){
            let exist = this.columns.find(x => x === column.orkl_name)
            return (exist) ? true : false
        },

        get_column_name(column) {
            let col = this.fields.find(x => x.orkl_name === column)
            if (col)
                return col.app_name;
            return '';
        }
    }
}
</script>
