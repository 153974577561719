<template>
    <el-dialog
        :visible.sync="show_modal"
        width="500px"
        append-to-body
        modal-append-to-body
        :close-on-click-modal="false"
        :destroy-on-close="true">
            
        <template slot="title"><div class="tl-dialog-title">New Search ISIN Filter</div></template>   

        <el-form 
            label-position="top" 
            label-width="140px" 
            :model="formdata" 
            size="small" 
            :rules="form_rules" 
            autocomplete="off" 
            class="tl-form"
            ref="UserFilterEditor">

            <el-form-item label="Name" prop="name">
                <el-input v-model="formdata.name" />
            </el-form-item>

            <el-form-item label="Description" prop="description">
                <el-input v-model="formdata.description" type="textarea" autosize />
            </el-form-item>

        </el-form>

        <div class="tl-form-buttons">
            <div class="spacer" />
            <el-button @click="show_modal = false">Cancel</el-button>
            <el-button type="primary" @click="save_record">Save</el-button>
        </div>

    </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            default() { return [] }
        },
        asset: {
            type: String,
            required: true,
        },
    },

    data(){
        return {
            show_modal : false,
            formdata   : {},
            form_rules : {
                name: [ { required: true, message: 'this field is required', trigger: 'blur' }, ],
            },
        }
    },

    computed: {
        ...mapState({
            user : state => state.app.user,
        })
    },

    methods: {
        save_record(){
            var $this = this;
            $this.$refs.UserFilterEditor.validate((valid) => {
                if (valid) {
                    $this.$refs.UserFilterEditor.clearValidate();
                    $this.formdata.user_id = $this.user.id
                    $this.formdata.asset = $this.asset
                    $this.formdata.fields = JSON.parse(JSON.stringify($this.columns))
                    $this.formdata.filters = JSON.parse(JSON.stringify($this.filters))
                    $this.formdata.filters.forEach(f => {
                        delete f.has_errors;
                        delete f.count_result;
                    })

                    $this.$store.dispatch('userFilters/addItem', $this.formdata).then(() => {
                        $this.$emit('saved');
                        $this.show_modal = false;
                    });
                } else { return false; }
            })
        }
    },

    watch: {
        open(val){
            if (val) {
                this.show_modal = true;
                this.formdata = {};
                if (this.$refs.UserFilterEditor) this.$refs.UserFilterEditor.clearValidate();
            }
        },
        show_modal(val){
            if (!val) {
                this.$emit('closed');
            }
        }
    }
}   
</script>
